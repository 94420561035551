<template>
  <div class="group-list">
    <vanTitle title="我的拼团" :url="state.url" v-if="state.source == ''"></vanTitle>
    <vanTitle title="我的拼团" v-else></vanTitle>
    <van-list v-model:loading="state.loading" loading-text="加载中..." :finished="state.finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="10">
      <van-cell v-for="(item ,index) in state.groupData" :key="index">
        <li class="item" @click="toGroupDetail(item.pid)">
          <div class="hd">
            <div class="lt">
              <img :src="item.thumb" class="lt-img">
            </div>
            <div class="rt">
              <div class="tiitle">{{item.title}}</div>
              <div class="bt">
                <div class="num">
                  <div class="price"><span>¥</span>{{item.group_price}}</div>
                  <div class="org-price">¥{{item.price}}</div>
                </div>
                <div class="join-progress" v-if="item.group_success == '0'">拼团进度{{item.number}}<span>/{{item.limit_number}}</span></div>
                <div class="join-progerror" v-if="item.group_success == '2'">拼团失败</div>
              </div>
            </div>
          </div>
          <div class="bt" v-if="item.group_success !== 2">
            <div class="share" v-if="item.group_success == '0'" @click.stop="bindshare(item.pid)">
              <i class="icon-share"></i>
              <span>分享</span>
            </div>
            <div class="learn-now" v-if="item.group_success == '1'" @click.stop="toDetail(item.shop_id,item.group_id)">立即学习</div>
          </div>
        </li>
      </van-cell>
    </van-list>
    <vantEmpty :show="state.empty_show" type="address" text="暂无拼团课程～"></vantEmpty>
    <tabbar></tabbar>

  </div>
</template>

<script>
import { reactive,ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import vantEmpty from '../../components/vantEmpty/index.vue'
export default {
  name: 'group-detail',
  components: {
    vantEmpty
  },
  setup() {
    const $route = useRoute()
    const $router = useRouter()
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const shooIdCon = reactive({
      shop: JSON.parse(localStorage.getItem('shop_id'))
    })
    const state = reactive({
      groupData: [], //拼团列表
      total: 0,
      loading: false,
      finished: false,
      page: 1,
      empty_show: false,
    
      url: `/index/${shooIdCon.shop}`,
      source: '' //课程来源
    })

    onMounted(() => {
      // 页面来源 我的 落地页
      if ($route.query.source) {
        state.source = $route.query.source
      }
      getData() //获取数据
    })

    //滚动加载时触发，list组件定义的方法
    const onLoad = () => {
      state.page++
      getData()
     
    }

    // 获取拼团列表
    const getData = () => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      proxy.$http
        .get('/api/group/my/' + state.page)
        .then((res) => {
          proxy.$toast.clear()
          const body = res.data.data

          if (res.data.code == '200') {
            state.total = body.total
            state.loading = false

            if (state.groupData == '' && body.data == '') {
              state.empty_show = true
            } else {
              state.empty_show = false
            }

            if (body.data == null || body.data.length === 0) {
              state.finished = true
              console.log('没有更多了')
              return
            }
            state.groupData = state.groupData.concat(body.data)
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    const toDetail = (shop_id, group_id) => {
      proxy.$http
        .get('/api/course/ref_id/' + shop_id + '/' + group_id + '/1')
        .then((res) => {
          console.log('拼团列表===>', res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            $router.push({
              name: 'detail',
              params: {
                dis_id: body.ref_id,
                group_id: group_id
              }
            })
          }
        })
        .catch((error) => console.log(error))
    }

    const toGroupDetail = (pid) => {
      $router.push({
        path: '/groupDetail',
        query: {
          gu_id: pid,
          source: 'list'
        }
      })
    }

    // 拼团详情打开分享弹窗
    const bindshare = (pid) => {
      store.commit('globalData/addShare', true) //全局参数
      $router.push({
        path: '/groupDetail',
        query: {
          gu_id: pid,
          source: 'list'
        }
      })
    }

    return {
      state,
      getData,
      onLoad,
      toDetail,
      toGroupDetail,
      bindshare,
      shooIdCon
    }
  }
}
</script>

<style scoped lang="scss">
.group-list {
  .item {
    margin: 12px 15px 0 15px;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px;
    .hd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .lt {
        width: 107px;
        height: 76px;
        background: url(../../assets/image/home/bg-zw.png) center/100%,
          100% no-repeat;
        border-radius: 2px;
        overflow: hidden;
        .lt-img {
          width: 100%;
          height: 100%;
        }
      }
      .rt {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 12px;
        flex: 1;
        min-height: 76px;
        .bt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .num {
            display: flex;
            align-items: flex-end;
          }
          .join-progress {
            padding: 2px 5px;
            font-size: 11px;
            color: #fc6c21;
            line-height: 20px;
            border-radius: 4px;
            background: rgba(252, 108, 33, 0.1);
            span {
              color: #999999;
            }
          }
          .join-progerror {
            padding: 2px 5px;
            font-size: 11px;
            color: #666666;
            line-height: 20px;
            border-radius: 4px;
            background: #ececec;
          }
        }
      }
    }
    .bt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 15px;
      .share {
        width: 60px;
        height: 24px;
        background: linear-gradient(137deg, #ff9d5b 0%, #fc6c21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-share {
          display: block;
          width: 13px;
          height: 13px;
          background: url(../../assets/image/group/icon-share.png) center/100%,
            100% no-repeat;
          margin-right: 1px;
        }
      }
      .learn-now {
        width: 72px;
        box-sizing: border-box;
        border-radius: 12px;
        border: 1px solid #fc6c21;
        text-align: center;
        line-height: 24px;
        font-size: 13px;
        color: #fc6c21;
      }
    }
  }
  /deep/ .van-cell {
    padding: 0 !important;
    background: none !important;
  }
}
</style>

