<template>
  <div class="mine-layout">
    <div class="mine-auth" v-if="state.userInfo == ''" @click="toLogin"></div>
    <div class="mine-header">
      <div class="header-info" v-if="state.userInfo == ''" @click="toLogin">
        <div class="info-lt">
          <img src="../../assets/image/mine/icon-head.png" />
        </div>
        <div class="info-rt">
          <div class="name">您还未登录</div>
          <div class="mobil">登录后可查看更多</div>
        </div>
        <img src="../../assets/image/mine/bg01.png" class="img-bg02" />
      </div>
      <div class="header-info" v-else>
        <div class="info-lt">
          <img :src="state.userInfo.avatar" />
        </div>
        <div class="info-rt">
          <div class="name">{{ state.userInfo.name }}</div>
          <div class="mobil">听课手机号：{{ state.userInfo.mobile }}</div>
        </div>
        <img src="../../assets/image/mine/bg01.png" class="img-bg02" />
      </div>
    </div>
    <section class="mine-content">
      <ul class="options-list">
        <router-link to="/order" class="option-item" tag="li">
          <div class="item-info">
            <img src="../../assets/image/mine/icon-order.png" class="icon-mine" />
            <span>我的订单</span>
          </div>
          <van-icon name="arrow" color="#999999" />
        </router-link>
        <div @click="toGroup" class="option-item" tag="li">
          <div class="item-info">
            <img src="../../assets/image/mine/icon-group.png" class="icon-mine" />
            <span>我的拼团</span>
          </div>
          <van-icon name="arrow" color="#999999" />
        </div>
        <router-link to="/address" class="option-item" tag="li">
          <div class="item-info">
            <img src="../../assets/image/mine/icon-address.png" class="icon-mine" />
            <span>地址管理</span>
          </div>
          <van-icon name="arrow" color="#999999" />
        </router-link>
        <div class="option-item" tag="li" @click="bindSignOut">
          <div class="item-info">
            <img src="../../assets/image/mine/icon-out.png" class="icon-mine" />
            <span>退出登录</span>
          </div>
          <van-icon name="arrow" color="#999999" />
        </div>
        <router-link to="/receive" class="option-item" tag="li">
          <div class="item-info">
            <img src="../../assets/image/mine/icon-free-course.png" class="icon-mine" />
            <span>免费领取-试听课程</span>
          </div>
          <div class="between">
            <div v-if="!is_receive" class="icon-new"></div>
            <div v-else class="reviced">领取成功</div>
          </div>
          <van-icon name="arrow" color="#999999" />
        </router-link>
      </ul>
    </section>
    <tabbar></tabbar>
  </div>
</template>

<script>
import {
  reactive,
  onMounted,
  getCurrentInstance,
  watchEffect,
  computed
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getStore } from '../../utils/util'
import { useStore } from 'vuex'
import Tabbat from '../../components/tabbar/index.vue'
export default {
  name: 'mine',
  components: {
    Tabbat
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const store = useStore()
    const state = reactive({
      courseList: [], //课程列表
      userInfo: '' //用户信息
    })

    onMounted(() => {
      let userInfo = getStore('userInfo')

      if (userInfo) {
        store.commit('userInfo/addUser', userInfo)
        state.userInfo = userInfo
      } else {
        state.userInfo = ''
      }
    })

    watchEffect(() => {
      state.userInfo = store.state.userInfo.userInfo
    })

    const is_receive = computed(() => {
      return store.state.globalData.receive_id != -1
    })

    /**
     * 跳转登录页
     */
    const toLogin = () => {
      $router.push({
        path: '/login'
      })
    }

    /**
     * 跳转我的拼团页
     */
    const toGroup = () => {
      $router.push({
        path: '/group',
        query: {
          source: 'mine'
        }
      })
    }

    // 退出登录
    const bindSignOut = () => {
      proxy.$dialog
        .confirm({
          title: '确定退出登录么？',
          confirmButtonColor: '#FC6C21'
        })
        .then(() => {
          store.commit('userInfo/setUser', '')
          store.commit('userInfo/setAccessToken', '')
          state.userInfo = ''
        })
        .catch(() => {
          // on cancel
        })
    }

    return {
      state,
      toLogin,
      toGroup,
      bindSignOut,
      is_receive
    }
  }
}
</script>

<style scoped lang="scss">
.mine-layout {
  background: #ffffff;
  .mine-auth {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .mine-header {
    background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
    .header-info {
      width: 348px;
      height: 131px;
      background: url(../../assets/image/mine/bg.png);
      background-size: 348px 131px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin: 0 auto;
      .info-lt {
        width: 48px;
        height: 48px;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info-rt {
        color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 16px;
        .name {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
        .mobile {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .img-bg02 {
        width: 100%;
        height: 51px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  .mine-content {
    padding: 0 16px 0 20px;
    .options-list {
      padding-top: 23px;
      .option-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 13px 0;
        .item-info {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333;
          .incon {
            padding-right: 16px;
          }
          .icon-mine {
            width: 24px;
            height: 24px;
            padding-right: 15px;
          }
        }
        .between {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 9px;
          .icon-new {
            width: 34px;
            height: 16px;
            background: url('../../assets/image/mine/icon-new.png') no-repeat 0
              0;
            background-size: contain;
          }
          .reviced {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
