<template>
	<div class="page-address">
        <vanTitle title="地址管理"></vanTitle>
        <van-list v-model:loading="state.loading" loading-text="加载中..." :finished="state.finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="10">
            <van-cell v-for="(item ,index) in state.dataList" :key="index">
                <li class="item" @click="bindSelect(item.id,item)">
                    <div class="item-info">
                        <div class="title f-owt-two">{{handleRepeatAddr(item.format_city_area, item.address)}}</div>
                        <div class="info-bt">
                            <span>{{item.name}}</span>
                            <span>{{item.phone}}</span>
                        </div>
                    </div>
                    <div class="item-method">
                        <div class="method-lt" @click.stop="bindDefault(item.id)">
                            <img src="https://file.huatu.com/static/miniprogram/shop/h5/address/icon_select.png" class="icon" v-if="item.default == 0">
                            <img src="https://file.huatu.com/static/miniprogram/shop/h5/address/icon_selected.png" class="icon" v-else>
                            <div class="lt-item">默认地址</div>
                        </div>
                        <div class="method-rt">
                            <div class="rt-item" @click.stop="toadd('编辑地址',item.id)">
                                <img src="https://file.huatu.com/static/miniprogram/shop/h5/address/icon_edit.png" class="icon">
                                <span>编辑</span>
                            </div>
                            <div class="rt-item" @click.stop="bindDelete(item.id)">
                                <img src="https://file.huatu.com/static/miniprogram/shop/h5/address/icon_delete.png" class="icon">
                                <span>删除</span>
                            </div>
                        </div>
                    </div>
                </li>
            </van-cell>
        </van-list>
		<vantEmpty :show="state.empty_show" type="address" :btnShow="true" btnText="去添加" text="暂无地址哦～" @bindBtn="toadd('添加地址')"></vantEmpty>
        <div class="big-btn" @click="toadd('添加地址')" v-if="state.dataList.length > 0">添加地址</div>
	</div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance, computed } from "vue";
// import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import vantEmpty from "../../components/vantEmpty/index.vue";
export default {
  name: "address",
  components: {
    vantEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const $route = useRoute();
    const state = reactive({
            addressData: [],//地址数据
            dataList: [],//地址列表
            loading: false,
            finished: false,
			page: 1,
            limit: 10,
            empty_show: false,
            is_back: false,
            fromOrder: false, // 埋点用的没啥大碍
            department: 0,
            course_id:'',
            from:''
        });


    onMounted(() => {
        // 是否返回上一页
        if($route.query.is_back){
            state.is_back = $route.query.is_back;
            state.fromOrder = true;
        }
        // 从课程列表中详情来
        if($route.query.course_id){
            state.course_id = $route.query.course_id;
        }
        if($route.query.from){
            state.from = $route.query.from;
        }

		getData();//获取数据
    })

    // 选择地址
    const bindSelect = (id,item) => {
        if(state.is_back){
            if(state.from=='order_detail'){
                // 提示 只有一次的更换地址机会
                 proxy.$dialog.confirm({
				title: '友情提示',
                message:'每个课程只有一次更换地址的机会，确定更换为新地址吗？',
				confirmButtonColor: '#FC6C21'
			})
				.then(() => {
                    // if(item.cityarea)
                    let val=item.cityarea.replace(/\//g,' ');
                    // 调用接口，课程换地址，成功跳回
                    proxy.$http.post("/api/order/revise_order_address",{
                        order_id: state.course_id,
                        name:item.name,
                        mobile:item.phone,
                        address:val+' '+item.address
                    })
                    .then(res => {
                        console.log("===>", res.data)
                        const body = res.data.data;

                        if(res.data.code == '200'){
                            $router.go(-1)
                        } else {
                            proxy.$toast(res.data.message);
                        }
                    })
                    .catch(error => console.log(error))
				})
				.catch(() => {
					// on cancel
				});
               
            }else{
                $router.go(-1)
                localStorage.setItem('address_id', id)
            }
        }
        
    }

    // 设置默认地址
    const bindDefault = (id) => {
        console.log(id);
        proxy.$dialog.confirm({
				title: '确定设置为默认地址？',
				confirmButtonColor: '#FC6C21'
			})
				.then(() => {
					setDefault(id)
				})
				.catch(() => {
					// on cancel
				});
    }

    // 设置默认地址
    const setDefault = (id) => {
        proxy.$http.post("/api/address/setdefault",{
                id: id
            })
			.then(res => {
				console.log("设置默认地址===>", res.data)
				const body = res.data.data;

				if(res.data.code == '200'){
					getData(1);
				} else {
					proxy.$toast(body.message);
				}
			})
			.catch(error => console.log(error))
    }

    // 删除地址
    const bindDelete = (id) => {
        console.log(id);
        proxy.$dialog.confirm({
            title: '确定删除地址？',
            confirmButtonColor: '#FC6C21'
        })
            .then(() => {
                setDelete(id)
            })
            .catch(() => {
                // on cancel
            });
    }

    // 删除地址
    const setDelete = (id) => {
        proxy.$http.post("/api/address/delete",{
                id: id
            })
			.then(res => {
				console.log("删除地址===>", res.data)
				const body = res.data.data;

				if(res.data.code == '200'){
					getData(1);
				} else {
					proxy.$toast(body.message);
				}
			})
			.catch(error => console.log(error))
    }

    // 跳转添加编辑地址页
	const toadd  = (name,id) => {
		$router.push({
			path: "/address/add",
			query: {
				name: name,
                id: id,
                fromOrder: state.fromOrder,
                department: state.department
			}
		});
	}

    //滚动加载时触发，list组件定义的方法
    const onLoad = () => {
        state.page++;
        getData();
        console.log("加载更多～")
    }

    // 获取地址列表
	const getData = (page) => {
        proxy.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });
        if(page == 1){
            state.page = page;
            state.dataList = [];
        }
		proxy.$http.get("/api/address",{
                params: {
                    page: state.page,
                    limit: state.limit
                }
            })
			.then(res => {
				proxy.$toast.clear();
				const body = res.data.data;

				if(res.data.code == '200'){
                    state.loading = false;
					state.addressData = body;

                    if(state.dataList.length <= 0 && body.data.length <= 0){
                        state.empty_show = true
                    }

                    if(body.data == null || body.data.length === 0){
						state.finished = true;
						return;
					}

                    state.department = body.department;

					state.dataList = state.dataList.concat(body.data);

				} else {
					proxy.$toast(body.message);
				}
			})
			.catch(error => console.log(error))
	}

    /**
    * 处理重复地址
    */
    const handleRepeatAddr = computed(() => {
        return (format_city_area, address) => {
            if(address.indexOf(format_city_area) !== -1) {
                let rexp = new RegExp(format_city_area);
                return address;
            } else {
                return format_city_area + address;
            }
        }
    })
	
	return {
        state,
		toadd,
        getData,
        onLoad,
        bindDefault,
        bindDelete,
        bindSelect,
        handleRepeatAddr
	}
  },

  methods: {}
};
</script>

<style scoped lang="scss">
.van-dialog__header--isolated{
    padding:10px !important;
}
.page-address {
	height: 100%;
	background: #F9F9F9;
    font-size: 14px;
	.item{
		border-radius: 8px;
		margin: 12px 15px 0 15px;
        background: #ffffff;
        padding: 15px 15px 0 15px;
        .item-info{
            border-bottom: 1px solid #E6E6E6;
            .title{
                text-align: start;
            }
            .info-bt{
                color: #666666;
                line-height: 20px;
                margin: 8px 0 12px 0;
                span{
                    padding-right: 10px;
                }
            }
        }
        .item-method{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 43px;
            color: #666666;
            .method-lt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon{
                    width: 14px;
                    height: 14px;
                    margin-right: 2px;
                }
            }
            .method-rt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .rt-item{
                    margin-left: 16px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .icon{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

	}
    /deep/ .van-cell{
		padding: 0 !important;
		background: none !important;
        border: none !important;
	}
    .big-btn{
        position: fixed;
        left: 50%;
        bottom: 24px;
        margin-left: -167.5px;
    }
}
</style>
